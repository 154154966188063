<template>
  <div
    id="container"
    class="h-100"
  >
    <b-row class="h-100">
      <b-col
        style="background: linear-gradient(180deg, #9991F2 0%, #756AED 85.04%);"
        class="d-flex align-items-center justify-content-center"
        cols="12"
        xl="1"
      >
        <p
          style="transform: rotate(-90deg); color: #FFFFFF;"
          class="font-large-2"
        >
          Overview
        </p>
      </b-col>
      <b-col
        cols="12"
        xl="6"
        class="p-0"
      >
        <b-card
          no-body
          class="h-100"
        >
          <b-card-header class="d-flex justify-content-center">
            <b-row class="d-flex flex-column justify-content-center">
              <b-card-title class="font-large-2 mb-1">
                Time Remaining
              </b-card-title>
              <b-card-sub-title class="d-flex justify-content-center">
                <Countdown :deadline="dateEnd" />
              </b-card-sub-title>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-row class="d-flex flex-column justify-content-center">
              <vue-apex-charts
                ref="totalPremChart"
                type="radialBar"
                height="700"
                :options="options"
                :series="data"
              />
              <div class="d-flex align-items-center flex-column">
                <b-media class="mb-3" no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="24"
                        icon="TrendingUpIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <b-card-text class="font-large-3 font-weight-bolder">
                      <animated-number
                        :value="this.totalPremium"
                        :format-value="formatToTotalPrem"
                        :duration="1000"
                      />
                    </b-card-text>
                  </b-media-body>
                </b-media>
                <b-media no-body>
                  <b-media-aside
                      class="mr-2"
                  >
                    <b-avatar
                        size="48"
                        variant="light-primary"
                    >
                      <feather-icon
                          size="24"
                          icon="TrendingUpIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <b-card-text class="font-large-3 font-weight-bolder">
                      <animated-number
                          :value="this.opSold"
                          :format-value="formatTotalOpSold"
                          :duration="1000"
                      />
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </div>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        xl="5"
      >
        <b-row class="d-flex align-content-center justify-content-center h-25">
          <b-card class="rounded-pill">
            <b-card-text class="font-weight-bolder ml-2 font-large-1 d-flex align-items-center justify-content-between">
              Progress Today
              <b-media-aside class="ml-2 mr-0">
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <p class="font-medium-5 m-0">
                    {{ employerInicials }}
                  </p>
                </b-avatar>
              </b-media-aside>
            </b-card-text>
          </b-card>
          <div class="d-flex justify-content-center align-items-center"
               :class="employerName.split(' ').length > 3 ? `w-100` : ``">
            <p class="ml-2 font-weight-bolder font-large-1">
              {{ employerName }}
            </p>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <b-card class="w-75">
            <b-row class="mb-1 ml-1">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    variant="light-info"
                  >
                    <feather-icon
                      size="24"
                      icon="UserIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <b-card-text class="font-weight-bolder font-large-1">
                    <animated-number
                      :value="this.employerOppSold"
                      :format-value="formatToOpSold"
                      :duration="1000"
                    />
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-row>
            <b-row class="mb-1 ml-1">
              <b-media no-body>
                <b-media-aside

                  class="mr-2"
                >
                  <b-avatar
                    size="48"
                    variant="light-success"
                  >
                    <feather-icon
                      size="24"
                      icon="DollarSignIcon"
                    />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <b-card-text class="font-weight-bolder font-large-1">
                    <animated-number
                      :value="this.employerTotalPremium.toFixed(0)"
                      :format-value="formatToPrice"
                      :duration="1000"
                    />
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-row>
            <trends-component
              :key="trendsComponent"
              :trends="this.trends"
              :show-op-sold="showOpSold"
              :show-stacked-chart="false"
            />
          </b-card>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BCardBody, BCardHeader, BCardText, BCardTitle, BCol, BLink, BRow, BAvatar, BMedia, BMediaBody,
  BMediaAside, BCardSubTitle, BIconLink
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Countdown from 'vuejs-countdown'
import AnimatedNumber from 'animated-number-vue'
import useJwt from '@/auth/jwt/useJwt'
import { analysError } from '@/_helpers/analys_error'
import TrendsComponent from '@/views/dashboard/TrendsComponent'
import { tv_timeouts } from '@/_helpers/tv_timeouts'
import {policy_is_sold} from "@/_helpers/check_policy_is_sold";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BRow,
    BButton,
    BCardBody,
    BCardTitle,
    BCardHeader,
    BCol,
    BAvatar,
    BMediaAside,
    BMedia,
    BMediaBody,
    BCardSubTitle,
    VueApexCharts,
    TrendsComponent,
    AnimatedNumber,
    Countdown,
    BIconLink
  },
  data() {
    return {
      trendsComponent: 0,
      totalPremium: 0,
      opSold: 0,
      data: [0],
      dates: [],
      totalPremiumPoliciesGoal: 0,
      totalOpSoldGoal: 0,
      options: {
        colors: ['#96D978'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#7367F0'], // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
        plotOptions: {
          radialBar: {
            size: 185,
            hollow: {
              size: '60%',
            },
            track: {
              margin: 15,
            },
            dataLabels: {
              name: {
                show: false,
                fontSize: '1rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '8rem',
                fontFamily: 'Montserrat',
                fontWeight: 500,
                offsetY: 40,
              },
            },
          },
        },
        legend: {
          show: false,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        stroke: {
          lineCap: 'round',
        },
        labels: ['Comments'],
      },
      dateStart: this.$moment().startOf('month').format('Y-MM-DD'),
      dateEnd: this.$moment().endOf('month').add('d', 1).format('Y-MM-DD'),
      policies: [],
      employers: [],
      activeEmployer: 0,
      employerPolicies: [],
      employerTotalPremium: 0,
      employerOppSold: 0,
      employerInicials: '',
      employerName: '',
      trends: {},
      showOpSold: false,
      fullscreen: false,
    }
  },
  watch: {
    activeEmployer() {
      this.employerName = this.employers[this.activeEmployer].name
      this.employerInicials = ''
      this.getEmployerReport()
      this.initEmployerInicials()
    },
    showOpSold() {
      this.trendsComponent += 1
    },
  },
  beforeMount() {
    this.getPeriod()
    this.getCompanyReport()
    this.getEmployers()
    const self = this
    setInterval(() => {
      if (self.activeEmployer !== self.employers.length - 1) {
        self.activeEmployer += 1
      } else {
        self.activeEmployer = 0
      }
    }, tv_timeouts.user)
    setInterval(() => {
      self.showOpSold = !self.showOpSold
    }, tv_timeouts.chart)
    setInterval(() => {
      self.getCompanyReport()
    }, tv_timeouts.premium)
  },
  methods: {
    formatTotalOpSold(value) {
      return `${Number(value).toFixed(0)} out of ${this.totalOpSoldGoal}`
    },
    formatToTotalPrem(value) {
      const totalPrem = Intl.NumberFormat('en').format(Number(value).toFixed(0))
      const totalPremGoal = Intl.NumberFormat('en').format(this.totalPremiumPoliciesGoal)
      return `$ ${totalPrem} out of ${totalPremGoal}`
    },
    formatToOpSold(value) {
      return `Opportunities Sold: ${Number(value).toFixed(0)}`
    },
    formatToPrice(value) {
      const totalPrem = Intl.NumberFormat('en').format(Number(value).toFixed(0))
      return `Total Premium: $ ${totalPrem}`
    },
    initEmployerInicials() {
      const nameArr = this.employerName.split(' ')
      nameArr.forEach((name, i) => {
        if (i < 3) {
          this.employerInicials += name.charAt(0)
        }
      })
    },
    getPeriod() {
      let i = 0
      while (i <= this.$moment(this.dateEnd).diff(this.dateStart, 'days')) {
        const date = this.$moment(this.dateStart).add(i, 'days').format('DD/MM')
        this.dates.push(date)
        i++
      }
    },
    getCompanyReport() {
      axios.get(`${process.env.VUE_APP_API_URL}/dashboard?from=${this.dateStart}&to=${this.dateEnd}&companyGoals`, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        this.loading = false
        this.policies = response.data.data.policies
        this.totalPremiumPoliciesGoal = response.data.data.totalPremiumPoliciesGoal
        this.totalOpSoldGoal = response.data.data.countPoliciesGoal
        this.calculateChartSeries()
      }).catch(error => {
        console.log(error)
        this.loading = false
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    },
    calculateChartSeries() {
      this.totalPremium = 0
      this.opSold = 0
      this.policies.map(policy => {
        if (policy.business_type === 'N' && policy_is_sold(policy)) {
          this.totalPremium += parseFloat(policy.total_premium)
          this.opSold++
        }
      })
      this.data = this.totalPremiumPoliciesGoal ? [(parseFloat(this.totalPremium.toFixed(2))
          / this.totalPremiumPoliciesGoal * 100).toFixed(0)] : [100]
      // this.totalPremium = number_to_money(this.totalPremium)
    },
    getEmployers() {
      axios.get(`${process.env.VUE_APP_API_URL}/employers?forTV`, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        this.employers = response.data.data
        this.employerName = this.employers[this.activeEmployer].name
        this.initEmployerInicials()
        this.getEmployerReport()
      }).catch(error => {
        console.log(error)
        this.loading = false
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    },
    getEmployerReport() {
      const dateStart = this.$moment().format('Y-MM-DD')
      const dateEnd = this.$moment().format('Y-MM-DD')
      axios.get(`${process.env.VUE_APP_API_URL}/dashboard?from=${dateStart}&to=${dateEnd}&employers=${this.employers[this.activeEmployer].id}&forTv`, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        this.employerPolicies = response.data.data.policies
        let soldPolicies = this.employerPolicies.filter(policy => policy_is_sold(policy))
        this.employerOppSold = soldPolicies.length
        if (!this.employerOppSold) {
          this.employerTotalPremium = 0
        } else {
          this.employerPolicies.map(policy => {
            this.employerTotalPremium += parseFloat(policy.total_premium)
          })
        }
        this.getTrends()
      }).catch(error => {
        console.log(error)
        this.loading = false
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    },
    getTrends() {
      axios.get(`${process.env.VUE_APP_API_URL}/dashboard?from=${this.dateStart}&to=${this.dateEnd}&employers=${this.employers[this.activeEmployer].id}&trends&forTv`, {
        headers: { Authorization: `Bearer ${useJwt.getToken()}` },
      }).then(response => {
        this.trends = response.data.data.trends
        this.trendsComponent += 1
      }).catch(error => {
        console.log(error)
        this.loading = false
        const errorRes = analysError(error.response)
        const self = this
        errorRes.forEach(value => {
          self.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: value,
              icon: 'CoffeeIcon',
              variant: 'error',
            },
          })
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
